@import "variables";
.modal {
  .modal-header {
    border: 0;
  }
  .modal-content {
  }
  .modal-footer {
    border: 0;
  }
}
.modal-title {
  font-size: 1rem;
}
.ant-modal-wrap {
  //z-index: 2147483604;
}
.ant-modal {
  &.full-screen {
    padding-bottom: 0px;
    width: 100% !important;
    height: 100%;
    overflow: hidden;
    top: 0;
    .ant-modal-content {
      width: 100% !important;
      height: 100%;
    }
    .ant-modal-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: inherit;
    }
  }
}
