// Variable overrides
// change the theme
/**
 * variable for theming write here and use in you app
 */

/** -----------------------------------------
=========== BOOTSTRAP VARIABLES =========
-------------------------------------------**/

$primary: #42299a !default; //bright-blue
$secondary: #f0f0f0 !default; //sky-blue
$success: #28a745 ; //turquoise
$info: #59e1ca !default; //seafoam-blue
$warning: #ffb757 !default; //pale-orange
$danger: #ff7272 !default; //salmon
$light: #ffffff !default;
$dark: #27353c !default; //blue-grey
$gray: #f6f8ff !default; //gray
$dark-gray: #65677b !default; //dark gray
$label-gray: #7f7f7f !default; //label-gray
$table-first-col: #f5f5f5 !default; //table-first-col
$text-muted: #b9b9b9 !default;
$font-family-base: "font-regular" !default;
$font-size-base: 1rem !default;

// Breadcrumbs
$breadcrumb-font-size: 1rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: $light !default;
$breadcrumb-divider-color: $secondary !default;
$breadcrumb-active-color: $dark !default;
$breadcrumb-divider: quote(">") !default;

//INPUT
$input-border-radius: 0 !default;
$input-focus-border-color: none !default;
$input-height: 40px !default;
$input-font-size: 1rem !default;
$input-border-width: 1px !default;
$input-border-color: #dfdede !default;

//FormLabel
$form-label-color: #7f7f7f;
//Link
// $link-color:                  $secondary !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;

//Button
$btn-font-size: 1rem !default;

//ToggleButton

//Spinner
$spinner-border-width: 2px !default;

//ProgressBar
$progress-border-radius: 0 !default;
$progress-height: 20px !default;

/** -----------------------------------------
=========== OTHER CUSTOM VARIABLES =========
-------------------------------------------**/
$page-bg-color: #eff0f5;
// ANIMATIONS
$animation-duration: 0.5s;

$layout-content-padding-left: 20px;
$layout-content-padding-right: 20px;
$layout-content-padding-top: 20px;
$layout-content-padding-bottom: 20px;

$header-height: 60px;
$header-color: #ffffff;

$breadcrumb-height: 42px;
$header-color: #ffffff;

$sidebar-width: 216px;
$sidebar-bg: #ffffff;
