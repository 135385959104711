.amountInput {
  .ant-input {
    border: 0;
    border-radius: 5px;
    font-size: 1.7rem;
    //text-align: center;
    width: 100%;
    font-family: font-bold;
  }
}
