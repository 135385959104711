@import "variables";
.mk-btn {
  //border-radius: 5px;
}
.ant-btn-dark {
  color: #fff;
  background-color: $dark;
  border-color: $dark;
  border-radius: 5px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
