.react-import-mapper {
  table {
    width: 100%;
    th {
      background: #efefef;
    }
    tr {
      th,
      td {
        border: 1px solid #dcdcdc;
        padding: 8px;
        select {
          border: 0;
          width: 100%;
        }
      }
      &.invalid-field {
        td:nth-child(2) {
          color: #ff7272 !important;
        }
      }
    }
  }
}
