// If you want to override variables do it here
@import "variables";
@import "ie_fix";
@import "fonts";
@import "modal";
@import "animation";
@import "button";
@import "form-element";
@import "third-party";
body {
  background-color: $page-bg-color;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
.fc-widget-normal,
#fc_frame {
  z-index: 999 !important;
}
.page-scroll {
  height: calc(100vh - #{$header-height});
}
.internet-error {
  color: #ffffff;
  text-align: center;
  padding: 5px;
  font-family: font-bold;
}
.h-full {
  height: calc(100vh - #{$header-height});
}
/*.scrollbar-container{
  height: calc(100vh - #{$header-height});
}*/
*:focus {
  outline: none;
}
.text-muted {
  color: #b9b9b9 !important;
}
// dashed divider
.dashed {
  border-bottom: 1px dashed #c4c4c4;
  text-align: center;
  height: 10px;
  margin-bottom: 10px;
}

.dashed span {
  background: #fff;
  padding: 0 5px;
}

//Toast
.Toastify__toast {
  border-radius: 5px;
  min-height: 80px;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1;
  padding: 0 15px;
  font-family: "font-light";
}
.Toastify__close-button {
  float: right;
}
.c-pointer {
  cursor: pointer !important;
}
.disabled-row{
  background-color: #dcdcdc;
}
.mk-dropdown {
  box-shadow: 0px 1px 4px 0px #dcdcdc;
  border-radius: 5px;
  .cust-selected-tags {
    line-height: 28px !important;
    overflow: hidden;
    max-width: 250px;
    margin-top: 8px;
    white-space: pre-line;
    height: auto !important;
  }
  .tag-list-item {
    margin: 0 !important;
    text-transform: capitalize;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #f7f7f7;
  }
}
.ant-tag {
  .ant-tag-close-icon {
    margin-right: 0;
    font-size: 10px;
  }
}
.ant-card {
  color: #000000;
  .ant-card-head-title {
    font-family: "font-medium";
  }
}
