@import "variables";
//remove focus
.form-control:focus {
  box-shadow: inset 0 0px 0 #fff;
  outline: none;
}

//remove arrow from input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
//INPUT
.react-tel-input .form-control,
input.form-control {
  border: solid 1.5px #c9c9c9;
  background-color: #ffffff;
  border-radius: 5px;
}

.react-tel-input {
  .form-control {
    padding-left: 63px;
    width: inherit;
    font-size: 1.5rem;
    font-family: "font-bold";
  }
  .flag-dropdown {
    outline: none;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #ffffff;
    border: 1.5px solid #c9c9c9;
    border-radius: 5px 0px 0px 5px;
  }
  .selected-flag {
    width: 52px;
    padding: 0 0 0 12px;
  }
}

select,
.ant-select-selector,
.ant-picker {
  //height: 40px!important;
  width: 100%;
  border-color: #dcdcdc;
  border-radius: 4px !important;
}
