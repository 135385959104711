@import "./themes/scss/variables";
@import "~antd/dist/antd.css";
@import "node_modules/bootstrap/scss/bootstrap";
@import "./themes/scss/_common.scss";
/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999999;
  .brand-title {
    top: 36%;
    text-align: center;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    h2 {
      font-family: font-bold;
      margin: 0;
    }
  }
}

.loader {
  top: 35%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 4px solid transparent;
  border-top-color: #66bb6a;
}

#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 4px solid #f2f2f2;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.shape {
  height: 3px;
  border-radius: 20px;
  margin: 0 auto 30px;
  position: relative;
  width: 60px;
  background-color: #66bb6a;
}
.section-padding {
  padding: 60px 0;
}
