.PageHeader {
  padding-left: 12px;
  padding-right: 12px;
  .backBtn {
    border: 0;
    svg {
      font-size: 1.6rem;
      vertical-align: sub;
    }
  }
}
